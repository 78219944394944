import type { Route } from 'nextjs-routes';

export const PAGE_TYPE_DICT: Record<Route['pathname'], string> = {
  '/': '首页',
  '/txs': '交易量',
  '/txs/kettle/[hash]': 'Kettle transactions',
  '/tx/[hash]': '交易详情',
  '/blocks': '区块',
  '/block/[height_or_hash]': '区块信息',
  '/accounts': '顶部账户',
  '/address/[hash]': '地址详细信息',
  '/verified-contracts': '经过验证的合约',
  '/contract-verification': '合约验证',
  '/address/[hash]/contract-verification': '地址的合约验证',
  '/tokens': '通证',
  '/token/[hash]': '通证详情',
  '/token/[hash]/instance/[id]': '通证实例',
  '/apps': 'DApps',
  '/apps/[id]': 'DApp',
  '/stats': '统计',
  '/api-docs': 'REST API',
  '/graphiql': 'GraphQL',
  '/search-results': '搜索结果',
  '/auth/profile': 'Profile',
  '/account/watchlist': 'Watchlist',
  '/account/api-key': 'API keys',
  '/account/custom-abi': 'Custom ABI',
  '/account/tag-address': 'Private tags',
  '/account/verified-addresses': '已验证地址',
  '/public-tags/submit': '提交公共标签',
  '/withdrawals': 'Withdrawals',
  '/visualize/sol2uml': 'Solidity UML diagram',
  '/csv-export': 'Export data to CSV file',
  '/deposits': 'Deposits (L1 > L2)',
  '/output-roots': 'Output roots',
  '/dispute-games': 'Dispute games',
  '/batches': 'Tx batches (L2 blocks)',
  '/batches/[number]': 'L2 tx batch details',
  '/blobs/[hash]': 'Blob details',
  '/ops': 'User operations',
  '/op/[hash]': 'User operation details',
  '/404': '404',
  '/name-domains': 'Domains search and resolve',
  '/name-domains/[name]': 'Domain details',
  '/validators': 'Validators list',
  '/gas-tracker': 'Gas tracker',

  // service routes, added only to make typescript happy
  '/login': 'Login',
  '/api/metrics': 'Node API: Prometheus metrics',
  '/api/log': 'Node API: Request log',
  '/api/media-type': 'Node API: Media type',
  '/api/proxy': 'Node API: Proxy',
  '/api/csrf': 'Node API: CSRF token',
  '/api/healthz': 'Node API: Health check',
  '/auth/auth0': 'Auth',
  '/auth/unverified-email': 'Unverified email',
};

export default function getPageType(pathname: Route['pathname']) {
  return PAGE_TYPE_DICT[pathname] || 'Unknown page';
}
