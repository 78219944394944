import {Box, Flex, Grid, GridProps, Image, Link, Text} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import React from 'react';

import type {CustomLinksGroup} from 'types/footerLinks';

import config from 'configs/app';
import type {ResourceError} from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';
import useColors from "../networkMenu/useColors";
import IconSvg from "../../shared/IconSvg";

const MAX_LINKS_COLUMNS = 4;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${config.UI.footer.frontendVersion}`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${config.UI.footer.frontendCommit}`;

const Footer = () => {
    const colors = useColors();
    const {data: backendVersionData} = useApiQuery('config_backend_version', {
        queryOptions: {
            staleTime: Infinity,
        },
    });
    const BLOCKSCOUT_LINKS = [];

    const frontendLink = (() => {
        if (config.UI.footer.frontendVersion) {
            return <Link href={FRONT_VERSION_URL} target="_blank">{config.UI.footer.frontendVersion}</Link>;
        }

        if (config.UI.footer.frontendCommit) {
            return <Link href={FRONT_COMMIT_URL} target="_blank">{config.UI.footer.frontendCommit}</Link>;
        }

        return null;
    })();

    const fetch = useFetch();

    const {isPlaceholderData, data: linksData} = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
        queryKey: ['footer-links'],
        queryFn: async () => fetch(config.UI.footer.links || '', undefined, {resource: 'footer-links'}),
        enabled: Boolean(config.UI.footer.links),
        staleTime: Infinity,
        placeholderData: [],
    });

    const colNum = isPlaceholderData ? 1 : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

    const renderNetworkInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
        return (
            <Flex
                gridArea={gridArea}
                flexWrap="wrap"
                columnGap={8}
                rowGap={6}
                mb={{base: 5, lg: 10}}
                _empty={{display: 'none'}}
            >
                {!config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/>}
                <NetworkAddToWallet/>
            </Flex>
        );
    }, []);

    const renderProjectInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
        return (
                <Box gridArea={gridArea} alignItems="center">
                    <IconSvg
                        name="networks/icon-placeholder"
                        boxSize={ 12 }
                        mb={ 2 }

                    />
                    <Text fontSize="sm">
                        @2023 草田链 版权所有
                        <Link href={`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011702009002`}
                              target="_blank">
                            沪公网安备 31011702009002号
                        </Link>
                        ICP备案： <Link href={`https://beian.miit.gov.cn/`} target="_blank">
                        沪ICP备17029890号-4
                    </Link>
                    </Text>
                    <Text fontSize="sm">共建合作：15900824215（微信同）
                    </Text>

                </Box>
        )
            ;
    }, [backendVersionData?.backend_version, frontendLink]);

    const containerProps: GridProps = {
        as: 'footer',
        px: {base: 12, lg: 12},
        py: {base: 4, lg: 8},
        borderTop: '1px solid',
        borderColor: 'divider',
        gridTemplateColumns: {base: '1fr', lg: 'minmax(auto, 600px) 1fr'},
        columnGap: {lg: '32px', xl: '100px'},
    };

    if (config.UI.footer.links) {
        return (
            <Grid {...containerProps}>
                <div>
                    {renderNetworkInfo()}
                </div>
            </Grid>
        );
    }

    return (
        <Grid
            {...containerProps}
            gridTemplateAreas={{
                lg: `
          "network links-top"
          "info links-bottom"
        `,
            }}
        >
            {renderNetworkInfo({lg: 'network'})}
            {renderProjectInfo({lg: 'info'})}

        </Grid>
    );
};

export default React.memo(Footer);
